import React from 'react';

import { PostContentCardTwo } from '../home/post-content-card-two';

const NoticiasListComponent = ({
    noticias = [],
    start = 0,
    numberOfArticles = 3,
    col = 6,
    postHeight = null,
}) => {
    // Renderizar una tarjeta de artículo
    const getPost = (post, index, md, height, width) => {
        if (!post) return null;
        return (
            <div key={post.slug} className={`col-12 col-md-${md ?? 6}`}>
                <PostContentCardTwo
                    author={post.author}
                    cover={post.cover}
                    publishedAt={post.publishedAt}
                    title={post.title}
                    slug={post.slug}
                    height={postHeight ?? height}
                    width={width}
                    url={post.url}
                    isPrimary={index === 0}
                />
            </div>
        );
    };

    // Obtener el subconjunto de noticias según 'start' y 'numberOfArticles'
    const getNoticiasDiv = () => {
        let filteredNoticias;

        if (numberOfArticles === -1) {
            // Si numberOfArticles es -1, obtenemos desde 'start' hasta el final
            filteredNoticias = noticias.slice(start);
        } else {
            // Si numberOfArticles es positivo, obtenemos el rango desde 'start' y el número de artículos
            filteredNoticias = noticias.slice(start, start + numberOfArticles);
        }

        const height = 376;
        const width = 376 / 1.5;

        // Renderizar los artículos cargados
        return filteredNoticias.map((articulo, index) => getPost(articulo, index, col, width, height));
    };

    return (
        <div className="row">
            {getNoticiasDiv()} {/* Renderizar artículos filtrados */}
        </div>
    );
};

export default NoticiasListComponent;
