import Image from 'next/image';

const CustomImageNoticiasComponent = ({ src, alt, priority = false, unoptimized = false }) => {
    return (
        <Image
            src={src}
            alt={alt}
            fill
            priority={priority}
            unoptimized={unoptimized}
            sizes="(max-width: 992px) 770px, (min-width: 993px) 770px"
        />
    );
};

export default CustomImageNoticiasComponent;
