import ButtonMaterial from "../shared/custom/button-material";

const NoticiasFlashButton = ({ text, onClick }) => {
    return (
        <ButtonMaterial
            text={text}
            onClick={onClick}
            variant="outlined"
        />
    );
}

export default NoticiasFlashButton;