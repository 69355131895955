import React, { useEffect, useState } from 'react';

import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { getDaysInMonth, parseISO, subDays, addDays } from 'date-fns';

const months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
];

const HomeFixturesByCalendar = ({ selectedDate, onSelectDate, availableDates }) => {
    const today = new Date();
    const startRange = subDays(today, 10);
    const endRange = addDays(today, 30);

    const [currentMonth, setCurrentMonth] = useState(selectedDate.getMonth());
    const [currentDay, setCurrentDay] = useState(selectedDate.getDate());

    useEffect(() => {
        if (availableDates.length > 0) {
            const parsedDates = availableDates.map((date) => parseISO(date)).sort((a, b) => a - b);
            const todayDate = parsedDates.find((date) => date >= today) || parsedDates[0];
            setCurrentMonth(todayDate.getMonth());
            setCurrentDay(todayDate.getDate());
            onSelectDate(todayDate);
        }
    }, [availableDates, onSelectDate]);

    const handleMonthChange = (event) => {
        const newMonth = event.target.value;
        setCurrentMonth(newMonth);
        const newDate = new Date(selectedDate.getFullYear(), newMonth, 1);
        onSelectDate(new Date(newDate.setDate(Math.min(currentDay, getDaysInMonth(newDate)))));
    };

    const handleDayChange = (event) => {
        const newDay = event.target.value;
        setCurrentDay(newDay);
        onSelectDate(new Date(selectedDate.getFullYear(), currentMonth, newDay));
    };

    const availableMonths = [];
    const availableDays = [];

    const filteredDates = availableDates.filter((date) => {
        const parsedDate = parseISO(date);
        return parsedDate >= startRange && parsedDate <= endRange;
    });

    filteredDates.forEach((date) => {
        const parsedDate = parseISO(date);
        const month = parsedDate.getMonth();
        const day = parsedDate.getDate();
        if (!availableMonths.includes(month)) {
            availableMonths.push(month);
        }
        if (month === currentMonth && !availableDays.includes(day)) {
            availableDays.push(day);
        }
    });

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '16px',
                marginTop: '16px',
                width: '100%',
            }}
        >
            <FormControl style={{ flex: 1, marginRight: '8px', textAlign: 'left' }} variant="standard">
                <InputLabel id="month-select-label" style={{ textAlign: 'left' }}>
                    Mes
                </InputLabel>
                <Select
                    labelId="month-select-label"
                    id="month-select"
                    value={currentMonth}
                    onChange={handleMonthChange}
                    label="Mes"
                >
                    {availableMonths.map((month) => (
                        <MenuItem key={month} value={month}>
                            {months[month]}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl style={{ flex: 1, textAlign: 'left' }} variant="standard">
                <InputLabel id="day-select-label" style={{ textAlign: 'left' }}>
                    Día
                </InputLabel>
                <Select
                    labelId="day-select-label"
                    id="day-select"
                    value={currentDay}
                    onChange={handleDayChange}
                    label="Día"
                >
                    {availableDays.map((day) => (
                        <MenuItem key={day} value={day}>
                            {day}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};

export default HomeFixturesByCalendar;
