import { TextField } from '@mui/material';

const InputMaterial = ({
    value,
    onChange,
    label,
    disabled,
    styles,
    maxLength,
    placeholder,
    classes,
    fullWidth,
    type,
    variant,
}) => {
    return (
        <TextField
            label={label ?? 'Nombre'}
            variant={variant ?? 'standard'}
            value={value}
            onChange={onChange}
            disabled={disabled}
            style={styles}
            maxLength={maxLength}
            placeholder={placeholder}
            className={`text-field-material ${classes}`}
            fullWidth={fullWidth ?? true}
            type={type ?? 'text'}
        />
    );
};

export default InputMaterial;
