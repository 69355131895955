const RightArrow = ({ iconWidth, iconHeight }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={iconWidth ?? 20}
            height={iconHeight ?? 20}
            viewBox="0 0 256 256"
        >
            <path
                fill="#000000"
                d="m234.83 125.17l-96-96A4 4 0 0 0 132 32v44h-28a4 4 0 0 0-4 4v96a4 4 0 0 0 4 4h28v44a4 4 0 0 0 2.47 3.7a4 4 0 0 0 4.36-.87l96-96a4 4 0 0 0 0-5.66ZM140 214.34V176a4 4 0 0 0-4-4h-28V84h28a4 4 0 0 0 4-4V41.66L226.34 128ZM44 80v96a4 4 0 0 1-8 0V80a4 4 0 0 1 8 0Zm32 0v96a4 4 0 0 1-8 0V80a4 4 0 0 1 8 0Z"
            />
        </svg>
    );
};

export default RightArrow;
